@import "~antd/dist/antd.css";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  min-height: calc(100vh - 236px);
  background: #f5f6f8;
}

.navbar {
  display: flex;
  padding: 0 44px;
  border-bottom: 1px solid rgba(228, 231, 242, 0.5);

  &__links {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.1vw;
    text-align: center;
    margin: 0 26px;
    padding: 12px 0;
    border-bottom: 1px solid transparent;

    a {
      color: #6f7c98;
    }

    &:hover {
      border-bottom: 1px solid #0071CE;

      a {
        color: #0071CE;
      }
    }

    &.active {
      border-bottom: 1px solid #0071CE;

      a {
        color: #0071CE;
      }
    }
  }
}

.footer {
  background: #f5f6f8;
  position: static;
  bottom: 0;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.l7-control-logo.l7-control {
  display: none;
}

.ant-table-column-sorter {
  opacity: 0;
}

.tableSortIcon {
  position: absolute;
  margin-left: 10px;
  top: 3px;
}

@import "consortium";
@import "generalInfo";
@import "flows";
@import "organizations";
@import "flowGraph";
@import "banners";
@import "statistics";
@import "main-organizations";
