.statistics {
  padding-bottom: 40px;

  &__title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #3b4168;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 10px;

    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      color: #3b4168;
      margin: 0;
    }
  }

  &__chartBox {
    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #3b4168;
      margin-bottom: 4px;
    }
  }

  &__col {
    margin-bottom: 30px;
    padding: 30px;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}
