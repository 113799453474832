.superset-frame {
  width: 100%;
  border: solid 0px rgb(211, 208, 208);
  overflow: hidden;
}

.monitoring-st {
  min-height: 1800px;
}

.consortium {
  min-height: 2080px;
}

.all-flows-st {
  min-height: 2210px;
}

.all-organization-st {
  min-height: 2150px;
}

.general-info-st {
  min-height: 1800px;
}

.statistics-st {
  min-height: 2580px;
}

.skeleton-container {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.skeleton {
  margin-top: 50px;
}

.code_future-st {
  min-height: 3850px;
}

.assessment {
  min-height: 2050px;
}