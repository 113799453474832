// inner container

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
  height: 50px !important;
  border: 0px !important;
}

.ant-select-dropdown {
  border-radius: 10px !important;
}
