.main-organizations {
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;

  &__title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #3b4168;
  }

  &__table {
    top: 36px;
  }

  &__statsTable {
    background-color: #fff;
    margin: 32px 0;
    padding: 32px;
    border-radius: 8px;
  }

  &__block {
    margin-top: 0;
    height: 557px;
    border: 1px solid #e7eaf3;
    overflow: auto;
    position: relative;
    margin-bottom: 32px;

    &--header {
      display: flex;
      justify-content: space-between;
      padding: 16px 12px;
      border-bottom: 1px solid #e7eaf3;
      position: sticky;
      top: 0;
      right: 0;
      background: #ffffff;
    }

    &--body {
      display: flex;
      justify-content: space-between;
      padding: 16px 12px;
      border-bottom: 1px solid #e7eaf3;

      &:hover {
        background: #f5fbff;
      }
    }

    &--column {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &--box {
      display: flex;
      align-items: center;
      cursor: default;
    }

    &--title {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #6f7c98;
    }

    &--icon {
      margin-left: 10px;
    }

    &--text {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #3b4168;
    }
  }

  &__tooltip {
    padding: 12px;
    background: #ffffff;
    border-radius: 4px;
    width: 206px;

    &--box {
      display: flex;
      justify-content: space-between;
    }

    &--title {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #6f7c98;
    }

    &--value {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #6f7c98;
    }

    &--text {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #909ebb;
      margin-left: 18px;
    }

    &--amount {
      font-family: "IBM Plex Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #909ebb;
    }
  }
}
