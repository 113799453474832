@import "banners";

.all-organization {
  padding-bottom: 40px;

  &__title {
    padding: 42px 40px;
    margin-bottom: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;

    &--text {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      color: #3b4168;
      margin: 0;
    }

    &--select {
      appearance: none;
      background: url("../assets/images/selectArrow.svg") no-repeat right;
      background-position-x: calc(100% - 16px);
      border: 1px solid #e4e7f2;
      box-sizing: border-box;
      border-radius: 4px;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 4px 0 4px 32px;
      outline: none;
      padding: 10px 50px 10px 16px;
      color: #3b4168;
    }
  }
}

.organization-table {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
}

.custom-paginate {
  &__arrowBtns {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    font-size: 24px;
    font-weight: bold;

    &:first-of-type {
      margin-right: 20px;
    }
  }

  &__filter {
    margin-bottom: 0;
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #909ebb;
  }

  &__clearBtn {
    cursor: pointer;
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #0071ce;
    //order: 1;
    margin: 0px 24px;
    background: none;
    border: none;
    outline: none;
  }

  &__pagesBy {
    font-family: IBM Plex Sans, sans-serif;
    color: #909ebb;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }

  &__select {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-right: 40px;
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    line-height: 20px;
    color: #0071ce;
  }

  &__text {
    margin-right: 22px;
  }
}

.ant-table-tbody>tr>td {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3b4168;
}

.organization-table {
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
}

.custom-paginate {
  &__select {
    border: none;
    //background: none;
    outline: none;
    font-size: 16px;
    line-height: 20px;
    color: #0071ce;

    padding: 5px 28px 5px 14px;
    ;
    border-radius: 9px;
    appearance: none;
    background: url("../assets/images/polygon.svg") no-repeat right;
    background-position-x: calc(100% - 16px);
    background-color: #FCFFFE;


  }
}

@media (max-width: 1280px) {
  .ant-table-tbody>tr>td {
    padding: 16px 4px !important;
  }
}

.ant-input-group {
  width: 30%;
}
