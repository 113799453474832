.generalInfo {

  &__flexBox {
    display: flex;
    justify-content: space-between;

    &--items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 32px;
      background: #ffffff;
      border-radius: 8px;
      margin: 16px;
      box-sizing: border-box;
    }

    &--title {
      display: flex;
      flex: auto;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #6f7c98;
    }

    &--value {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      color: #15b012;

      &-span {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #909ebb;
        margin-left: 8px;
      }
    }

    &--box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-items {
        margin-right: 32px;
      }

      &-text {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #6f7c98;
      }

      &-content {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #3b4168;
      }
    }
  }

  &__tabsBox {
    display: flex;
    justify-content: space-between;

    &--tabsBlock {
      padding: 32px;
      background: #ffffff;
      border-radius: 8px;
      height: 100%;

      &-title {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #3b4168;
      }
    }

    &--chartsBlock {
      &-item {
        padding: 32px;
        background: #ffffff;
        border-radius: 8px;
      }
    }
  }

  &__tabs {
    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #3b4168;
    }
  }
}

@media (max-width: 1440px) {
  .generalInfo {
    &__flexBox {
      &--items {
        padding: 25px;
      }

      &--value {
        margin-bottom: 0.5em;
      }
    }
  }
}

@media (max-width: 1280px) {
  .generalInfo {
    &__flexBox {
      &--items {
        padding: 25px;
      }

      &--title {
        flex: 0 0 30%;
      }
    }
  }
}
