.all-flows {
  padding-bottom: 40px;

  &__title {
    padding: 42px 40px;
    margin-bottom: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;

    &--text {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      color: #3b4168;
      margin: 0;
    }

    &--select {
      background: #ffffff;
      border: 1px solid #e4e7f2;
      box-sizing: border-box;
      border-radius: 4px;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 4px 0;
      outline: none;
      padding: 10px 50px 10px 16px;
      color: #3b4168;
    }
  }

  &__flexBox {
    display: flex;
    //justify-content: space-between;
    align-items: flex-start;

    &--tabs {
      width: 100%;

      &__box {
        background: #ffffff;
        border-radius: 8px;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 32px;

        &:first-child {
          margin-bottom: 32px;
        }
      }
    }
  }
}

.ant-table-thead>tr>th {
  color: #6f7c98 !important;
  background: transparent !important;

  &:before {
    display: none;
  }
}

.ant-table table {
  border: 1px solid #f0f0f0;
}

.ant-tabs>.ant-tabs-nav {
  &:before {
    display: none;
  }
}

.ant-table-tbody>tr:nth-child(1)>td {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #3b4168;
}
