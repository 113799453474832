.flowGraph {
  padding-bottom: 40px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 40px;
    border-radius: 10px;

    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      color: #3b4168;
      margin: 0;
    }

    &--selector {
      width: 260px;
    }
  }

  &__chartBox {
    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #3b4168;
      margin-bottom: 4px;
    }
  }

  &__col {
    margin-top: 30px !important;
    padding: 30px !important;
    background: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}

.ant-select {
  font-family: IBM Plex Sans, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #3b4168 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #e4e7f2 !important;
  border-radius: 10px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
}

.ant-select-arrow {
  right: 16px !important;
}

.flowsBanners {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  &__bannerBox {
    border-radius: 20px;
    background: #fff;
    width: 32%;
    padding: 30px;
    overflow: hidden;

    &--row {
      margin-bottom: 24px !important;
    }

    &--col {
      display: flex !important;
      align-items: center !important;
    }

    &__carouselBlock {
      position: relative;
      margin-bottom: 24px;
      padding-left: 20px;

      &__current {
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 100%;
          left: 0;
          top: 0;
          background: #7be7c7;
        }
      }

      &__planned {
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 100%;
          left: 0;
          top: 0;
          background: #73aaff;
        }
      }

      &__last {
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 100%;
          left: 0px;
          top: 0px;
          background: #f3b944;
        }
      }
    }

    &--flowTitle {
      margin: 0 0 15px 0 !important;
      font-family: IBM Plex Sans, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #3b4168;
    }

    &--emptyFlowTitle {
      margin-top: 25px !important;
      font-family: IBM Plex Sans, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #6f7c98;
    }

    &--flowModule {
      margin: 0 0 8px 0 !important;
      font-family: IBM Plex Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #6f7c98;

      &_active {
        color: #3b4168;
      }
    }

    &--svg {
      cursor: pointer;
      display: block;
      margin: 0 10px;
    }

    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #6f7c98;
    }

    &--arrowBtn {
      border: none;
      background: none;
    }
  }
}

.flowsDuration {
  padding: 0;
  background: #fff;
  margin: 32px 0;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;

  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #3b4168;
  }

  &__subtitle {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: #15b012;
  }

  &__info {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6f7c98;

    &--text {
      color: #15b012;
    }
  }

  &__img {
    width: 100%;
    object-position: center;
    object-fit: contain;
  }
}

@media (max-width: 1280px) {
  .flowsBanners__bannerBox--svg {
    margin: 0 7px;
  }
}