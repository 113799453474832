.banners {
  &__flexBox {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 32px;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }

    &--title {
      display: flex;
      flex: auto;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #6f7c98;
      margin-bottom: 24px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    &--value {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      color: #15b012;
      margin-bottom: 20px;

      &-span {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #909ebb;
        margin-left: 8px;
      }
    }

    &--box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-items {
        margin-right: 32px;
      }

      &-text {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #6f7c98;
        margin-bottom: 0.5em;
      }

      &-content {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #3b4168;
        margin-bottom: 0.5em;
      }
    }

    &--img {
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: 0;
      object-fit: contain;
      object-position: center;
    }
  }
}

.ant-divider-horizontal {
  margin: 10px 0;
}

@media (max-width: 1280px) {
  .banners {
    &__flexBox {
      &--box {
        &-content {
          font-size: 16px;
        }
      }

      &--value {
        font-size: 38px;

        &-span {
          font-size: 14px;
        }
      }
    }
  }
}

.organization-banners {
  margin-bottom: 32px;

  &__flexBox {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    height: 100%;
    padding: 0;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;

    &--title {
      display: flex;
      flex: 0 0 37%;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #6f7c98;
      margin-bottom: 24px;

      &:first-child {
        margin-bottom: 0;
      }
    }

    &--value {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      color: #15b012;
      margin-bottom: 20px;

      &-span {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #909ebb;
        margin-left: 8px;
      }
    }

    &--box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-items {
        margin-right: 32px;
      }

      &-text {
        font-family: IBM Plex Sans, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: #6f7c98;
        margin-bottom: 0.5em;
      }

      &-content {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: #3b4168;
        margin-bottom: 0.5em;
      }
    }

    &--img {
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: 0;
      object-fit: contain;
      object-position: center;

      &_bookWithMarker2 {
        right: 25%;
      }
    }
  }
}
