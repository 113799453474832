.consortium {

  &__flexBox {
    display: flex;
    gap: 30px;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #3b4168;
  }

  &__metrics {
    padding: 32px 0 0 32px;
    background: #ffffff;
    border-radius: 8px;

    &--descr {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-family: IBM Plex Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #6f7c98;
    }

    &--value {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      color: #3b4168;
    }

    &--modalLink {
      font-family: IBM Plex Sans, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #0071ce;
      cursor: pointer;
    }

    &--stick {
      width: 1px;
      height: 80%;
      background-color: #e4e7f2;
    }
  }

  &__subtitle {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #6f7c98;
  }

  &__value {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    color: #15b012;
  }

  &__modal {
    padding: 32px;

    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #3b4168;
    }
  }
}

.consortium-box__var-four {
  height: 100%;
}

.chart {
  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #3b4168;
  }

  &__box {
    padding: 32px;
    background: #ffffff;
    border-radius: 8px;
    height: 100%;
  }
}

.map {
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 32px;

  &__title {
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #3b4168;
  }

  &__chart {
    &--title {
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: #3b4168;
    }

    &--block {
      padding: 32px;
      background: #ffffff;
      border-radius: 8px;
    }
  }
}

.chartsBlock {
  margin-top: 32px;
}

.ant-input-wrapper.ant-input-group {
  width: 100%;
}

@media (max-width: 1024px) {
  .consortium {
    &__metrics {
      &--descr {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__subtitle {
      font-size: 17px;
    }
  }
}
